<template>
    <div class="flex flex-wrap justify-center pt-10 text-sm text-white md:justify-end open-semi cusShadow md:text-xl">
        <a href="#" class="block px-3 mx-1" v-scroll-to="{el:'#service',duration: 1500,}">Service</a>
        <a href="#" class="block px-3 mx-1" v-scroll-to="{el:'#about',duration: 1500,}">About</a>
        <a href="#" class="block px-3 mx-1" v-scroll-to="{el:'#choose',duration: 1500,}">Choose Us</a>
        <a href="#" class="block px-3 mx-1" v-scroll-to="{el:'#contact',duration: 1500,}">Contact</a>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>