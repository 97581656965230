<template>
    <div class="bg-center bg-no-repeat bg-cover" style="background-image: url(../images/bg2.jpg)">
        <div class="container mx-auto">
            <TheHeader/>
            <div class="text-white cusPadd">
                <h1 class="text-3xl text-center md:text-5xl md:text-left lg:text-7xl cusShadow poppin-semi">
                    <span class="text-base lg:text-5xl md:text-2xl">Your Dream is</span> <br>
                    Our Blueprint
                </h1>
                <div class="w-32 h-1 mx-auto my-6 bg-white md:mx-0"></div>
                <h2 class="italic text-center uppercase md:text-xl lg:text-2xl open-semi cusShadow md:text-left ">New Approach To Interior Design</h2>
                <div class="flex flex-wrap justify-center mx-0 mt-5 text-sm text-center uppercase lg:text-lg sm:justify-start poppin-semi ">
                    <a href="tel:+60367317715" class="block px-10 py-3 mx-auto rounded-md cusBG2 md:mx-0">Call Us Now</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'

export default {
    components: {
        TheHeader
    }
}
</script>

<style>
.cusShadow {text-shadow: 2px 2px 2px #000;}

/* Slogan */
.cusPadd{padding: 21% 0 17% 3%;}
</style>