<template>
  <div class="mx-auto maxWidth">

    <!-- Header -->
    <TheBanner/>

    <!-- Service -->
    <div class="py-8 bg-gray-200 lg:py-16" id="service">
      <div class="container mx-auto">
        <div class="lg:flex lg:flex-wrap justify-evenly">
          <div class="p-3 lg:p-0 lg:w-1/2 lg:pt-28">
            <img src="/images/service.png" alt="Interior Design" class="mx-auto">
          </div>
          <div class="lg:w-1/3 xl:w-1/4">
            <img src="/images/service1.jpg" alt="Interior Design" class="mx-auto boxShadow">
            <div class="px-3 py-10 text-gray-600 bg-white lg:pl-8 boxShadow lg:px-0">
              <h1 class="text-2xl leading-tight text-center lg:text-left lg:text-5xl poppin-bold">Design & Planning</h1>
              <br>
              <p class="text-sm text-center md:text-lg lg:text-left lg:pr-10 open-semi">
                Budget Proposal, Layout Planning, Sketch Design, 3D Impression Drawings, Finishes Selection, Props,
                Detail Drawings & Project Management.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- About Us -->
    <div class="text-gray-700 bg-gray-200 bg-right bg-no-repeat bg-contain xl:py-16 cusBgimg">
      <div><img src="/images/about.jpg" alt="About Us" class="block lg:hidden"></div>
      <div class="container mx-auto">
        <div>
          <div class="lg:w-3/4">
            <h1 class="pt-6 pb-4 text-2xl text-center md:text-3xl lg:text-5xl lg:text-left lg:pt-0 poppin-bold" id="about">About Us</h1>
            <p class="px-3 text-sm md:text-base lg:px-0">
              We believe making your dream comes true is hassle free. At <b>V Tech Resources Sdn Bhd</b>, we offer professional one-stop services
              for your home. Our Team consists of experienced Project Managers and Supervisors to meets the unique needs of diverse range
              of customers customizing to their different requirement.
              <br>
              As Designer, we believe that our practice is acutely responsible for the well being of all those who inhabit our work.
              <br><br>
              <span class="text-xl"><b>Our mission</b></span> <br>
              Our mission is to get our minds and vision around all espects of the design brief <br>
              - Structure, Space, Program and Image <br>
              - To create an integrated system of form and expression.
              <br><br>
              We thick holistically to embrace our clients image and embody it in a build enviroment that is distinct in its character and is of the 
              highest integrity and quality. Rooted to providing quality workmanship and a consistent development of design, our core value is to provide 
              affordable fees and design excellence for our customers and deliver quality projects on ime and budget that exceeds our clients exprience.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Gallery -->
    <div class="py-8 bg-gray-200 lg:py-16">
      <div class="flex flex-wrap justify-center xl:px-4">
        <div class="w-1/2 xl:w-1/4"><img src="/images/gallery-01.jpg" alt="Interior Design" class="mx-auto"></div>
        <div class="w-1/2 xl:w-1/4"><img src="/images/gallery-02.jpg" alt="Interior Design" class="mx-auto"></div>
        <div class="w-1/2 xl:w-1/4"><img src="/images/gallery-03.jpg" alt="Interior Design" class="mx-auto"></div>
        <div class="w-1/2 xl:w-1/4"><img src="/images/gallery-04.jpg" alt="Interior Design" class="mx-auto"></div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="pb-16 text-gray-700 bg-gray-200">
      <div class="container mx-auto">
        <h1 class="pb-4 text-2xl text-center md:text-3xl lg:text-5xl poppin-bold" id="choose">Why Choose Us</h1>
        <div class="justify-center text-center md:flex md:lex-wrap md:items-center open-normal">
          <div class="px-4 mx-auto md:w-1/3 md:px-0">
            <b>- Listen -</b> <br> <span class="text-sm">Understand the needs of client and collect all available data, opinions and research</span>
            <br><br>
            <b>- Explore -</b> <br> <span class="text-sm">Throw the creative net wide to gather the full range of factors and influencs which may contribute to the project</span>
            <br><br>
            <b>- involve -</b> <br> <span class="text-sm">Include the client team in an open-minded and stimulating brainstorming process which challenges preconceptions</span>
          </div>
          <div class="px-4 mx-auto md:w-1/3 md:px-0">
            <b>- Distill -</b> <br> <span class="text-sm">Refine all the ideas to date and extract the essence for the most appropriate solution</span>
            <br><br>
            <b>- Express -</b> <br> <span class="text-sm">Clearly communication the vision for the project through design</span>
            <br><br>
            <b>- Execute -</b> <br> <span class="text-sm">Implement and deliver the project on time and to fit the budget</span>
          </div>
        </div>
        <div class="flex flex-wrap items-center justify-center">
          <div class="w-1/2 h-full p-4 mx-auto lg:w-1/4" v-for="(item, i) in chooseUs" :key="i">
            <div class="pb-1">
              <img :src="item.image" :alt="item.alt" class="mx-auto text-center"/>
            </div>
            <p class="pb-2 text-center text-black open-semi">{{ item.alt }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Gallery -->
    <div class="bg-gray-200">
      <div class="container mx-auto text-gray-700">
        <h1 class="pb-4 text-2xl text-center md:text-3xl lg:text-4xl poppin-bold">Gallery</h1>
        <TheGallery/>
      </div>
    </div>

    <!-- Footer -->
    <div class="pt-16 cusBG">
      <div class="container mx-auto">
        <h1 class="text-2xl italic text-center text-white poppin-bold" id="contact">V Tech Resources Sdn Bhd <br> <span class="text-xs">(Register No: 201901042748)</span></h1>
        <div class="w-32 h-0.5 mx-auto my-3 cusBG2"></div>
        <div class="justify-center md:flex md:flex-wrap cusTextcolor poppin-semi">
          <div class="px-8 mx-auto mb-5 text-center w-72 lg:mx-0 sm:mb-0">
            Contact Us at :
            <a href="tel:+60321087004" class="block"> 03 - 21087004</a>
          </div>
          <div class="w-auto px-8 mx-auto my-4 text-center lg:mx-0 lg:my-0 ">
            Address : <br/>
            The Horizon <br/>
            Avenue 7, Level 7, Tower 7, <br/>
            Bangsar South, <br/>
            9200 Kuala Lumpur, Malaysia
          </div>
          <div class="px-8 mx-auto text-center w-72 lg:mx-0">
            Leave a Message At :
            <a href="mailto:vtechresources1@gmail.com" class="block">vtechresources1@gmail.com</a>
          </div>
        </div>
      </div>
      <div class="w-full h-0.5 mx-auto my-8 cusBG2"></div>
      <div class="px-3 mx-auto md:px-0 md:w-1/2 cusTextcolor poppin-semi">
        <p class="mb-5 text-center">
          Find Us at :
        </p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1991.9680319556096!2d101.66589833865798!3d3.1116190274726896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc498757fa116b%3A0x972b7b2ebdec0073!2sThe%20Horizon%2C%20Tower%203%2C%20Avenue%207!5e0!3m2!1sen!2smy!4v1692165798694!5m2!1sen!2smy" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.6921734019506!2d101.65808201523244!3d3.1754871538745553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc488a138d5f51%3A0x4d8abcb995733562!2sBlock%20L-6-7%2C%202%2C%20Jalan%20Solaris%202%2C%20Solaris%20Mont%20Kiara%2C%2050480%20Kuala%20Lumpur%2C%20Wilayah%20Persekutuan%20Kuala%20Lumpur!5e0!3m2!1sen!2smy!4v1652863581337!5m2!1sen!2smy" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
      </div>
      
      <div class="w-full h-0.5 mx-auto mt-8 cusBG2"></div>
      <div class="py-3 font-sans text-center text-white">All Right Reserve @</div>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

// @ is an alias to /src
import TheBanner from '@/components/TheBanner.vue'
import TheGallery from '@/components/TheGallery.vue'

export default {
  name: 'Home',
  components: {
    TheBanner,
    TheGallery
  },
  data (){
    return{
      chooseUs: [
        { image: "images/choose-01.png", alt: "Complete More than 60+ Projects",},
        { image: "images/choose-02.png", alt: "98% Customer Satisfaction" },
        { image: "images/choose-03.png", alt: "Painting" },
        { image: "images/choose-04.png", alt: "Wall Tiles & Floor Tiles" },
      ],
    }
  }
}
</script>

<style>
/* Font */
.poppin-semi {font-family: "Poppins", sans-serif; font-weight: 600;}
.poppin-bold {font-family: "Poppins", sans-serif; font-weight: 700;}

.open-normal {font-family: "Open Sans", sans-serif; font-weight: 400;}
.open-semi {font-family: "Open Sans", sans-serif; font-weight: 600;}
.open-bold {font-family: "Open Sans", sans-serif; font-weight: 700;}

/* Max Width */
.maxWidth{max-width: 1920px;}

/* Shadow */
.boxShadow{box-shadow: 2px 2px 8px #b1b1b1;}

/* Footer BG */
.cusBG{background: #212120;}
.cusBG2{background: #161616;}
.cusTextcolor{color: #7C7C7C;}

@media (min-width: 1024px) {
  .cusBgimg{background-image: url("/images/about.jpg")}
}
@media (min-width: 1280px) {
  
}
</style>
