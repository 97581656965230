<template>
    <div>
        <CoolLightBox :items="items" :index="index" @close="index = null">
          </CoolLightBox>

         <div class="flex flex-wrap justify-center">
            <div v-for="(image, imageIndex) in items" :key="imageIndex" @click="index = imageIndex" class="w-1/2 cursor-pointer md:w-1/3 lg:w-1/5">
              <img :src="image" class="p-1 mx-auto"/>
            </div>
          </div>

    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    components: {
        CoolLightBox,
    },
    data(){
        return{
            items: [
                "/images/gallery-05.jpg",
                "/images/gallery-06.jpg",
                "/images/gallery-07.jpg",
                "/images/gallery-08.jpg",
                "/images/gallery-09.jpg",
            ],
            index: null,
        }
    }
}
</script>

<style>

</style>